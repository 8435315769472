import { Typography } from '@bowtie-ins/bowkit';
import {
  FemaleReproductiveSystem,
  UnknownPreExisting,
  UrinarySystem,
} from '@bowtie-ins/bowkit/icons';
import styled from '@emotion/styled';
import { Box, BoxProps, Link, LinkProps, Theme } from '@mui/material';
import snakeCase from 'lodash/snakeCase';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HealthIcon from 'src/assets/img/icon_health.svg';
import HyperLinkIcon from 'src/assets/img/icon_hyperlink.svg';
import TanitaIcon from 'src/assets/img/icon_tanita.svg';
import XrayIcon from 'src/assets/img/icon_xray.svg';
import { colours, LANGUAGE } from 'src/constants';
import { Report } from 'src/graphql';

const HoverBox = styled(Box)<LinkProps & BoxProps & { theme?: Theme }>(
  ({ theme }) => ({
    transition: 'background-color 0.2s ease-out',
    color: 'unset',
    textDecoration: 'none',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
    '&:first-of-type': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: +theme.shape.borderRadius * 2,
    },
    '&:last-of-type': {
      borderBottomLeftRadius: +theme.shape.borderRadius * 2,
      borderBottomRightRadius: +theme.shape.borderRadius * 2,
    },
  }),
);

const ReportDayItem: React.FC<{
  report: Report;
  first: boolean;
  last: boolean;
}> = ({ report, first, last }) => {
  const { t, i18n } = useTranslation('report');
  const API_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT;
  const { media, original_report } = report;

  const mapStringToIcon = (str: string) => {
    switch (str) {
      case 'Body Composition Analysis':
        return <img src={TanitaIcon} alt={str} height={20} width={24} />;
      case 'Blood Pressure Results':
        return <img src={HealthIcon} alt={str} height={20} width={24} />;
      case 'X-Ray':
        return <img src={XrayIcon} alt={str} height={24} width={24} />;
      case 'Urinalysis':
        return <UrinarySystem />;
      case 'Pap Smear':
        return <FemaleReproductiveSystem />;
      default:
        return <UnknownPreExisting />;
    }
  };

  if (!media && !original_report) {
    return null;
  }

  const key = media?.sk || report.pk;

  const href = media
    ? `${API_ENDPOINT}/report/${media.pk}/media/${media.sk}/file`
    : `${API_ENDPOINT}/report/${report.pk}/original`;

  return (
    <HoverBox
      component={Link}
      key={key}
      display="flex"
      alignItems="center"
      px={2.5}
      pt={first ? 2.5 : 1}
      pb={last ? 2.5 : 1}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <Box display="flex" color={colours.SECONDARY} mr={2}>
        {mapStringToIcon(report.name)}
      </Box>
      <Box>
        <Typography
          variant="body-l"
          fontWeight={i18n.language === LANGUAGE.ZH ? 'regular' : 'medium'}
        >
          {t(`report.${snakeCase(report.name)}`, t('report.body_check_report'))}
          <img
            style={{
              marginLeft: 8,
              verticalAlign: 'middle',
            }}
            src={HyperLinkIcon}
            alt="view"
          />
        </Typography>
      </Box>
    </HoverBox>
  );
};

export default ReportDayItem;
