import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import { useTheme } from '@mui/material';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from 'src/auth/context';
import { BASENAME } from 'src/constants';
import Routes from 'src/routes';
import { initAmplify } from 'src/utils/initAmplify';
import { initApolloClient } from 'src/utils/initApolloClient';
import { initAutoMinHeight } from 'src/utils/initAutoMinHeight';

initAmplify({
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  cookies: {
    domain: process.env.REACT_APP_COOKIES_DOMAIN,
    path: process.env.REACT_APP_COOKIES_PATH,
  },
});

const App: React.FC = () => {
  const theme = useTheme();
  initAutoMinHeight();

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={initApolloClient()}>
          <Router basename={BASENAME}>
            <Routes />
          </Router>
        </ApolloProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
