import { string } from 'yup';

const hkidFormatRegex = /^([a-zA-Z]{1,2}[0-9]{6})(\([0-9Aa]\)|[0-9Aa])$/;

const checkValidChecksum = (value: string) => {
  const matches = String(value).match(hkidFormatRegex);
  if (!matches) return false;
  const [digest, ckm] = Array(...matches).slice(1, 3);

  const checksum = ckm.replace('(', '').replace(')', '').toUpperCase();
  // Digits preserve their values; Letters continue the counting with A = 10, B = 11, ...
  const ordinals = digest
    .toUpperCase()
    .split('')
    .map(character =>
      character.charCodeAt(0) >= 48 && character.charCodeAt(0) <= 57
        ? parseInt(character, 10)
        : 10 + character.charCodeAt(0) - 'A'.charCodeAt(0),
    );

  // Account for the space for single letter case
  if (ordinals.length === 7) ordinals.unshift(36);

  const ordinalSum = ordinals.reduce(
    (acc, cur, ix) => acc + cur * (ordinals.length - ix + 1),
    0,
  );
  const modulus = ordinalSum % 11;

  if (modulus === 0) return checksum === '0';
  if (modulus === 1) return checksum === 'A';

  return checksum === String(11 - modulus);
};

const isValidHkid = (value?: string) =>
  value === undefined || checkValidChecksum(value);

export const hongKongIdentityNumber = () =>
  string()
    .uppercase()
    .test({
      message: 'invalid.hkid.format',
      test: value => isValidHkid(value),
    });

const LOCALSTORAGE_DATA_PRIVACY_KEY = 'data-privacy-accepted';
export const isDataPrivacyRead = () => {
  const dataPrivacy = localStorage.getItem(LOCALSTORAGE_DATA_PRIVACY_KEY);
  return dataPrivacy === 'true';
};

export const setDataPrivacyRead = () => {
  localStorage.setItem(LOCALSTORAGE_DATA_PRIVACY_KEY, 'true');
};
