import { ApolloError, ServerError, useApolloClient } from '@apollo/client';
import { Typography } from '@bowtie-ins/bowkit';
import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useHashOtp, useOtpLogin, useRecaptcha } from 'src/auth/hooks';
import Alert from 'src/components/Alert';
import { AUTH_ERROR_CODES, Path } from 'src/constants';
import { LoginForm } from 'src/features/auth/components/index';
import { isDataPrivacyRead } from 'src/features/auth/utils';
import { Patient, RETRIEVE_PATIENT_ME } from 'src/graphql';

const LoginConfirmVerification = () => {
  const { t } = useTranslation('auth');
  const { verify, ready } = useRecaptcha('login_confirm');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const { success, errorCode, confirmLogin } = useOtpLogin();
  const { userId } = useHashOtp();
  const client = useApolloClient();

  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      setIsInitialized(true);
      const token = await verify();
      await confirmLogin(token);
      setLoading(false);
    };
    if (ready && !isInitialized) {
      fn().then();
    }
  }, [confirmLogin, ready, isInitialized, verify]);

  if (success) {
    // decide where to redirect to
    // 1) check for user verification status, if not verified, redirect to verify page
    // 2) check for data privacy read status, if not read, redirect to data privacy page
    const fn = async () => {
      try {
        const me = await client.query<{
          me: Patient;
        }>({
          query: RETRIEVE_PATIENT_ME,
        });
        console.log(me);

        if (isDataPrivacyRead()) {
          history.push(Path.Home);
        } else {
          history.push(Path.DataPrivacy);
        }
      } catch (e) {
        if (
          ((e as ApolloError)?.networkError as ServerError)?.statusCode === 400
        ) {
          history.push(Path.Verify);
        }
      }
    };
    fn().then();
  }

  if (loading) {
    return (
      <Box
        justifyContent="center"
        height="calc(100vh - 300px)"
        display="flex"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {errorCode && errorCode.type !== AUTH_ERROR_CODES.EXPIRED_CODE_EXCEPTION && (
        <Box mb={2} px={3}>
          <Alert severity="error">
            {errorCode.type === AUTH_ERROR_CODES.USER_NOT_FOUND_EXCEPTION ? (
              <Typography variant="body-m">
                <Trans
                  defaults={t(errorCode.type, errorCode.message)}
                  components={{
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    a: <a href={Path.Login} />,
                  }}
                />
              </Typography>
            ) : (
              <Typography variant="body-m">
                {t(errorCode.type, errorCode.message)}
              </Typography>
            )}
          </Alert>
        </Box>
      )}
      {errorCode && errorCode.type === AUTH_ERROR_CODES.EXPIRED_CODE_EXCEPTION && (
        <>
          <Box textAlign="center" maxWidth={280} m="auto">
            <Typography variant="headline-s">{t('login.title')}</Typography>
            <Typography variant="body-l" fontWeight="medium">
              {t('login.desc')}
            </Typography>
          </Box>
          <LoginForm userId={userId} />
        </>
      )}
    </>
  );
};

export default LoginConfirmVerification;
