import { Typography } from '@bowtie-ins/bowkit';
import { INK, WHITE } from '@bowtie-ins/bowkit/palette';
import { ArrowDown, Exit, ShieldSecurity } from '@bowtie-ins/bowkit/icons';
import styled from '@emotion/styled';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/auth/context';
import { LANGUAGE, Path } from 'src/constants';
import {
  PrivacyPolicy,
  PrivacyPolicyZh,
} from 'src/features/account/components/index';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CustomList = styled(List)<{ theme?: Theme }>(({ theme }) => ({
  li: {
    '.MuiButtonBase-root': {
      height: 62,
    },
    '.MuiIconButton-root': {
      height: 46,
      width: 46,
    },
  },
  '.MuiListItemIcon-root': {
    color: INK,
    marginLeft: theme.spacing(0.5),
    minWidth: 32,
  },
  '.MuiListItemButton-root': {
    paddingRight: theme.spacing(8),
  },
  '.MuiListItemSecondaryAction-root': {
    right: 20,
  },
}));

const AccountMenu: React.FC = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation('account');
  const auth = useAuth();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const signOut = async () => {
    await auth.signOut();
    history.push(Path.Root);
  };

  const renderItems = [
    {
      icon: <ShieldSecurity />,
      label: t('account.privacy'),
      onClick: () => setOpen(true),
      secondaryAction: (
        <IconButton edge="end" onClick={() => setOpen(true)}>
          <ArrowDown style={{ transform: 'rotate(-90deg)' }} />
        </IconButton>
      ),
    },
    {
      icon: <Exit />,
      label: t('account.logout'),
      onClick: signOut,
      secondaryAction: null,
    },
  ];

  return (
    <Box bgcolor={WHITE}>
      <CustomList disablePadding>
        {renderItems.map((item, i, items) => (
          <React.Fragment key={i}>
            <ListItem disablePadding secondaryAction={item.secondaryAction}>
              <ListItemButton onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="body-l"
                    fontWeight={
                      i18n.language === LANGUAGE.ZH ? 'regular' : 'medium'
                    }
                  >
                    {item.label}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {i + 1 !== items.length && <Divider />}
          </React.Fragment>
        ))}
      </CustomList>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
      >
        {i18n.language === LANGUAGE.EN && (
          <PrivacyPolicy onClose={() => setOpen(false)} />
        )}
        {i18n.language === LANGUAGE.ZH && (
          <PrivacyPolicyZh onClose={() => setOpen(false)} />
        )}
      </Dialog>
    </Box>
  );
};

export default AccountMenu;
