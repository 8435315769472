import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DocumentHead from 'src/components/DocumentHead';
import { Path } from 'src/constants/path';
import AccountDetail from 'src/pages/account/AccountDetail';
import DataPrivacy from 'src/pages/auth/DataPrivacy';
import Login from 'src/pages/auth/Login';
import LoginConfirmation from 'src/pages/auth/LoginConfirmation';
import Register from 'src/pages/auth/Register';
import Verify from 'src/pages/auth/Verify';
import Home from 'src/pages/Home';

const Routes = () => {
  return (
    <>
      <DocumentHead />
      <Switch>
        {/* before login*/}
        <Route exact path={Path.Login} component={Login} />
        <Route exact path={Path.Register} component={Register} />
        <Route exact path={Path.DataPrivacy} component={DataPrivacy} />
        <Route exact path={Path.Callback.Login} component={LoginConfirmation} />

        {/* after login*/}
        <Route exact path={Path.Verify} component={Verify} />
        <Route exact path={Path.Home} component={Home} />
        <Route exact path={Path.Account} component={AccountDetail} />

        {/*fallback*/}
        <Redirect to={Path.Login} />
      </Switch>
    </>
  );
};

export default Routes;
