import { Typography, TypographyProps } from '@bowtie-ins/bowkit';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGE } from 'src/constants';

const SIZE = 50;

const LanguageButton = (props: Pick<TypographyProps, 'variant'>) => {
  const { t, i18n } = useTranslation('common');
  const handleChange = (lang: LANGUAGE) => {
    i18n.changeLanguage(lang);
    // todo: if logged in, it should PATCH the language to the backend
  };

  const langToBeChange =
    i18n.language === LANGUAGE.EN ? LANGUAGE.ZH : LANGUAGE.EN;

  return (
    <Button
      sx={{
        minWidth: SIZE,
        width: SIZE,
        height: SIZE,
        borderRadius: '50%',
      }}
      onClick={() => handleChange(langToBeChange)}
    >
      <Typography variant={props.variant || 'body-l'}>
        {t(`common.${langToBeChange}`)}
      </Typography>
    </Button>
  );
};

export default LanguageButton;
