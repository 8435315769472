import { User } from '@bowtie-ins/bowkit/icons';
import { Box } from '@mui/material';
import React from 'react';
import { colours } from 'src/constants';
import { WHITE } from '@bowtie-ins/bowkit/palette';

const Avatar: React.FC<{ size?: number }> = ({ size = 32 }) => (
  <Box
    display="flex"
    bgcolor={WHITE}
    justifyContent="center"
    alignItems="center"
    color={colours.PRIMARY}
    border={`1px solid ${colours.PRIMARY}`}
    borderRadius="50%"
    width={size}
    height={size}
  >
    <User style={{ width: '100%', height: (size * 18) / 32 }} />
  </Box>
);

export default Avatar;
