import React from 'react';

/**
 * A context creation helper that doesn't require default context values
 */
export const createContext = <T>() => {
  const Context = React.createContext<T | undefined>(undefined);
  const useContext = () => {
    const ctx = React.useContext(Context);
    if (!ctx) throw new Error('useContext must be inside a Provider!');
    return ctx;
  };
  return [useContext, Context] as const;
};
