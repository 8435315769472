// TODO: refactor into individual files
import { Auth } from '@aws-amplify/auth';
import { AuthError } from '@aws-amplify/auth/lib/Errors';
import { useCallback, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'src/auth/context';
import { RecaptchaAction } from 'src/auth/types';
import { AUTH_ERROR_CODES, Path, RECAPTCHA_V3_KEY } from 'src/constants';
import { prependBasePath } from 'src/utils/routing';
import { base64ToUUID } from './helpers';

export type SignUpForm = {
  phone_number: string;
  plato_given_id: string;
  language: string;
};

type RequestOtpForm = {
  phone_number: string;
};

type AuthFormError = {
  type: string;
  message: string;
} | null;

// export type ConfirmSignUpForm = RequestOtpForm;
export type ResetPasswordForm = RequestOtpForm;

const HK_COUNTRY_CODE_PREFIX = '+852';

type CognitoError = AuthError & {
  code?: string;
  __type?: string;
};

const DEFAULT_MEDIUM = 'SMS';

const getRandomString = () => {
  const randomValues = new Uint8Array(32);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues)
    .map((nr: number) => {
      return nr.toString(16).padStart(2, '0');
    })
    .join('');
};

const getCognitoErrorMessage = (error: unknown) => {
  const cognitoError = error as CognitoError;
  const errorCode =
    cognitoError?.__type || cognitoError?.code || 'UnexpectedAuthException';
  const errorMessage =
    errorCode === 'ExpiredCodeException'
      ? 'invalid.expired_code'
      : cognitoError?.message;
  if (errorCode === AUTH_ERROR_CODES.USER_LAMBDA_VALIDATION_EXCEPTION) {
    try {
      const obj = JSON.parse(errorMessage.replace(/^.*({.*}).*$/, '$1'));
      return {
        type: obj.code,
        message: obj.message,
      };
    } catch {
      return {
        type: errorCode,
        message: `${errorMessage}`,
      };
    }
  }
  return {
    type: errorCode,
    message: `${errorMessage}`,
  };
};

// Temporary workaround for accounts with non-HK phone numbers
const sanitizePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.startsWith('+')) return phoneNumber;
  return `${HK_COUNTRY_CODE_PREFIX}${phoneNumber}`;
};

export const useHashOtp = (): {
  userId: string;
  otp: string;
} => {
  const { hash } = useLocation();

  const [userId, otp] = useMemo(() => {
    // The URL: format should be in
    // /l#<base64 encoded UUID>\d{6}
    const hashString = hash.slice(1);

    const _userId = (function (f) {
      try {
        return base64ToUUID(f);
      } catch {
        return f;
      }
    })(hashString.slice(0, -6));
    const _otp = hashString.slice(-6);

    return [_userId, _otp] as const;
  }, [hash]);

  return { userId, otp };
};

export const useSignUp = (initialValues: SignUpForm) => {
  const [form, setForm] = useState<SignUpForm>(initialValues);
  const [errorCode, setErrorCode] = useState<AuthFormError>(null);
  const [otpMedium, setOtpMedium] = useState('');

  const signUp = async (recaptcha_token?: string | null) => {
    try {
      const {
        phone_number: phoneWithoutPrefix,
        plato_given_id,
        language,
      } = form;
      const phone_number = sanitizePhoneNumber(phoneWithoutPrefix);
      const password = getRandomString();
      const response = await Auth.signUp({
        username: phone_number,
        password: password,
        attributes: {
          phone_number,
          email: `info+${plato_given_id}@bowtiejphealth.com`,
          locale: language,
          'custom:plato_given_id': plato_given_id,
        },
        clientMetadata: {
          language,
          callback: prependBasePath(Path.Login),
          origin: window.location.origin,
          ...(recaptcha_token && { recaptchaToken: recaptcha_token }),
        },
        validationData: {},
      });
      const medium =
        response?.codeDeliveryDetails?.DeliveryMedium || DEFAULT_MEDIUM;
      setErrorCode(null);
      setOtpMedium(medium);
      return true;
    } catch (err) {
      setErrorCode(getCognitoErrorMessage(err));
      setOtpMedium('');
      return false;
    }
  };

  return {
    form,
    setForm,
    errorCode,
    signUp,
    otpMedium,
  };
};

export const useRequestLoginOtp = (defaultForm: ResetPasswordForm) => {
  // TODO: implement with formik instead
  const [form, setForm] = useState<ResetPasswordForm>(defaultForm);
  const [errorCode, setErrorCode] = useState<AuthFormError>(null);
  const [otpMedium, setOtpMedium] = useState('');

  const requestLoginOtp = async (
    language: string = 'zh',
    recaptcha_token?: string | null,
    userId?: string,
  ) => {
    try {
      const { phone_number } = form;
      setErrorCode(null);
      const response = await Auth.forgotPassword(
        userId || sanitizePhoneNumber(phone_number),
        {
          language,
          callback: prependBasePath(Path.Callback.Login),
          origin: window.location.origin,
          ...(recaptcha_token && { recaptchaToken: recaptcha_token }),
        },
      );
      const medium =
        response?.codeDeliveryDetails?.DeliveryMedium || DEFAULT_MEDIUM;
      setOtpMedium(medium);
      setErrorCode(null);
      return true;
    } catch (err) {
      const error = getCognitoErrorMessage(err);
      if (error.type === AUTH_ERROR_CODES.USER_NOT_FOUND_EXCEPTION) {
        setErrorCode(null);
        setOtpMedium(DEFAULT_MEDIUM);
        return true;
      }
      console.error(err);
      setErrorCode(error);
      setOtpMedium('');
      return false;
    }
  };

  return {
    otpForm: form,
    setOtpForm: setForm,
    requestLoginOtp,
    otpMedium,
    errorCode,
  };
};

export const useOtpLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<AuthFormError>(null);
  const { verify } = useAuth();

  const { userId, otp } = useHashOtp();

  const confirmLogin = useCallback(
    async (recaptcha_token?: string | null) => {
      try {
        setErrorCode(null);
        setLoading(true);
        const randomPassword = getRandomString();
        await Auth.forgotPasswordSubmit(userId, otp, randomPassword, {
          ...(recaptcha_token && { recaptchaToken: recaptcha_token }),
        });
        await Auth.signIn(userId, randomPassword);
        await verify({ bypassCache: true });
        setSuccess(true);
      } catch (err) {
        setErrorCode(getCognitoErrorMessage(err));
      } finally {
        setLoading(false);
      }
    },
    [userId, otp, setErrorCode, verify],
  );

  return {
    success,
    loading,
    errorCode,
    confirmLogin,
  };
};

export const useRecaptcha = (action: RecaptchaAction) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verify = () => {
    return executeRecaptcha ? executeRecaptcha(action) : Promise.resolve(null);
  };

  return { verify, ready: !RECAPTCHA_V3_KEY || !!executeRecaptcha };
};
