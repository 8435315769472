import account from './account.json';
import auth from './auth.json';
import common from './common.json';
import meta_title from './meta_title.json';
import report from './report.json';
import yup from './yup.json';

export default {
  auth,
  common,
  meta_title,
  yup,
  account,
  report,
};
