import {
  Button as BaseButton,
  ButtonProps,
  Typography,
} from '@bowtie-ins/bowkit';
import { ROCK, WHITE } from '@bowtie-ins/bowkit/palette';
import styled from '@emotion/styled';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { colours } from 'src/constants';

const UnstyledButton = (props: ButtonProps) => {
  return (
    <BaseButton {...props} className={`${props.className}`}>
      <Typography variant="title-s">{props.children}</Typography>
    </BaseButton>
  );
};

const Button = styled(UnstyledButton)<{ disabled: boolean; theme?: Theme }>(
  ({ disabled, theme }) => {
    const bgColor = (colour: string) => ({
      backgroundColor: colour,
      borderColor: colour,
    });
    return {
      '&&': {
        ...bgColor(colours.SECONDARY),
        color: colours.PRIMARY,
        borderRadius: +theme.shape.borderRadius * 5,
        borderWidth: 4,
        '&:active, &:hover': {
          ...bgColor(colours.PRIMARY),
          color: colours.SECONDARY,
        },
        ...(disabled && {
          ...bgColor(ROCK),
          color: WHITE,
        }),
      },
    };
  },
);

export default Button;
