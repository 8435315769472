import { Box, Button, Container, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Link, useHistory, useLocation } from 'react-router-dom';
import BowtieJpLogo from 'src/assets/img/logo.svg';
import { useAuth } from 'src/auth/context';
import { LanguageButton, LayoutBox } from 'src/components';
import { colours, Path, RECAPTCHA_V3_KEY } from 'src/constants';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const { authenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (![Path.Login, Path.Register].includes(location.pathname)) {
      return;
    }
    if (authenticated) {
      history.push(Path.Home);
    }
  }, [authenticated, history, location.pathname]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_KEY}>
      <LayoutBox>
        <Box
          zIndex={theme.zIndex.appBar}
          position="sticky"
          maxWidth={600}
          margin="auto"
          top={0}
          left={0}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2.5}
          mb={6.25}
          bgcolor={colours.BACKGROUND}
          boxShadow={`0 -20px 20px 20px ${colours.BACKGROUND}`}
        >
          <Button sx={{ pointerEvents: 'none' }}></Button>
          <Button to={Path.Login} component={Link}>
            <img src={BowtieJpLogo} height="45" alt="logo" />
          </Button>
          <LanguageButton />
        </Box>
        <Container maxWidth="xs">{children}</Container>
      </LayoutBox>
    </GoogleReCaptchaProvider>
  );
};

export default Layout;
