import { Typography } from '@bowtie-ins/bowkit';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { base64ToUUID } from 'src/auth/helpers';
import DocumentHead from 'src/components/DocumentHead';
import { Layout, LoginForm } from 'src/features/auth/components';

const Login = () => {
  const { t } = useTranslation('auth');
  const { hash } = useLocation();

  // Only prefill User ID when it's valid
  const userId = React.useMemo(() => {
    if (hash.length > 1) {
      try {
        return base64ToUUID(hash.slice(1));
      } catch {
        console.warn(`Invalid base64 uuid: ${hash.slice(1)}`);
      }
    }
    return undefined;
  }, [hash]);

  return (
    <Layout>
      <DocumentHead titleKey="meta_title.login" />
      <Box textAlign="center" maxWidth={280} m="auto">
        <Typography variant="headline-s">{t('login.title')}</Typography>
      </Box>
      <LoginForm userId={userId} />
    </Layout>
  );
};

export default Login;
