import React from 'react';
import DocumentHead from 'src/components/DocumentHead';
import { DataPrivacyAgreement, Layout } from 'src/features/auth/components';

const DataPrivacy = () => {
  return (
    <Layout>
      <DocumentHead titleKey="meta_title.data-privacy" />
      <DataPrivacyAgreement />
    </Layout>
  );
};

export default DataPrivacy;
