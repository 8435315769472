import { ApolloError, ServerError, useMutation } from '@apollo/client';
import { Typography } from '@bowtie-ins/bowkit';
import { Id } from '@bowtie-ins/bowkit/icons';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Alert from 'src/components/Alert';
import Button from 'src/components/Button';
import { Path } from 'src/constants';
import { CONFIRM_ACCOUNT, RETRIEVE_PATIENT_ME } from 'src/graphql';
import { useQs } from 'src/utils/routing';
import { object } from 'yup';
import { hongKongIdentityNumber } from '../utils';
import TextField from './TextField';

const VerificationForm: React.FC = () => {
  const { t } = useTranslation(['auth', 'common']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const qs = useQs();
  const [confirmAccount] = useMutation(CONFIRM_ACCOUNT, {
    refetchQueries: [
      {
        query: RETRIEVE_PATIENT_ME,
      },
    ],
  });

  const initialValues = {
    identity_document_number: qs?.identity_document_number || '',
  };

  type VerificationProps = {
    identity_document_number: string;
  };

  const validationSchema = () => {
    return object({
      identity_document_number: hongKongIdentityNumber(),
    });
  };

  const handleSubmit = async (values: VerificationProps) => {
    setLoading(true);
    setError('');
    try {
      const confirmFn = (identity_document_number: string) => {
        return confirmAccount({
          variables: {
            input: { document_number: identity_document_number },
          },
        });
      };
      await Promise.all([
        await confirmFn(values.identity_document_number).catch(() => {
          // retry without "(" and ")" in identity_document_number
          confirmFn(values.identity_document_number.replace(/[()]/g, ''));
        }),
        new Promise(r => setTimeout(r, 2000)),
      ]);
      history.push(Path.Home);
    } catch (e) {
      const err = (e as ApolloError)?.networkError as ServerError;
      setError(err.result?.code);
      setLoading(false);
    }
  };

  return (
    <Formik<VerificationProps>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const value = `${event.target.value}`.toUpperCase();
          setFieldValue(event.target.name, value);
        };

        return (
          <Form noValidate>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="100%" p={2.75} display="grid" gap={1}>
                {!loading && error && (
                  <Box mb={2}>
                    <Alert severity="error">
                      <Typography variant="body-m">{t(error)}</Typography>
                    </Alert>
                  </Box>
                )}
                <TextField
                  autoFocus
                  name="identity_document_number"
                  mask="a999999(9)"
                  label={t('common.hkid', { ns: 'common' })}
                  icon={<Id />}
                  onChange={handleChange}
                  value={values.identity_document_number}
                  errorMessage={
                    touched.identity_document_number
                      ? errors.identity_document_number
                      : ''
                  }
                />
                <Box mt={2}>
                  <Button
                    size="s"
                    type="submit"
                    fullWidth
                    loading={loading}
                    disabled={loading || !values.identity_document_number}
                  >
                    {!loading && t('verification.confirm')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VerificationForm;
