export const base64ToUUID = (base64Str: string): string => {
  // Convet from URL safe base64 to normal base64
  const uuidStr = window
    .atob(base64Str.replaceAll('-', '+').replaceAll('_', '/'))
    .split('')
    .map(aChar => ('0' + aChar.charCodeAt(0).toString(16)).slice(-2))
    .join('');
  return uuidStr.replace(
    /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/,
    '$1-$2-$3-$4-$5',
  );
};
