import { Typography } from '@bowtie-ins/bowkit';
import { INK } from '@bowtie-ins/bowkit/palette';
import { Close, ShieldSecurity } from '@bowtie-ins/bowkit/icons';
import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';
import React from 'react';

export const Container = styled('div')({
  marginTop: 10,
  paddingBottom: 60,
  fontFamily:
    'Gilroy, Noto Sans CJK TC, Noto Sans TC, Helvetica, Arial, sans-serif',
  p: {
    marginBottom: 10,
    '&:has(strong:only-child)': {
      margin: '24px 0',
    },
  },
  li: {
    fontWeight: 400,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    letterSpacing: '0.005em',
    display: 'block',
    position: 'relative',
    paddingLeft: '3.5em',
  },
  ol: {
    'counter-reset': 'item',
  },
  'li:before': {
    content: 'counters(item, ".") " "',
    'counter-increment': 'item',
    position: 'absolute',
    left: 0,
  },
  '> ol': {
    li: {
      paddingLeft: '1.5em',
      '> ol': {
        li: {
          paddingLeft: '2.5em',
          '> ol': {
            li: {
              paddingLeft: '3.5em',
            },
            '> ol': {
              li: {
                paddingLeft: '4.5em',
              },
            },
          },
        },
      },
    },
  },
});

const PrivacyPolicy: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box py={2} px={3}>
      <Box display="flex" justifyContent="space-between">
        <ShieldSecurity style={{ color: INK }} />
        <IconButton onClick={onClose}>
          <Close style={{ color: INK }} />
        </IconButton>
      </Box>
      <Container>
        <Typography variant="headline-s">Privacy Policy Statement</Typography>
        <Typography variant="body-m">
          Bowtie x JP Partners Medical Centre Limited ("<strong>we</strong>", "
          <strong>our</strong>" or "<strong>us</strong>") consider the privacy
          of our patients, users of our services, visitors of our facilities and
          clinic(s), and visitors and users of our website (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bowtiejphealth.com/"
          >
            https://www.bowtiejphealth.com/
          </a>
          ), applications and other platforms ("
          <strong>you</strong>" or "<strong>your</strong>") is of utmost
          importance. This statement establishes how we collect personal data
          from your use of our services, participation in our clinical
          diagnostic procedures, visit of our facilities and clinic(s), and
          visit and use of our website, applications and other platforms and how
          we use or disclose such personal data.
        </Typography>
        <Typography variant="body-m">
          <strong>OUR POLICY</strong>
        </Typography>
        <Typography variant="body-m">
          We are committed to protecting the privacy, confidentiality and
          security of personal data we collect and hold by complying with the
          requirements of the Personal Data (Privacy) Ordinance (Cap. 486) ("
          <strong>PDPO</strong>"). Please visit the website of the Office of the
          Privacy Commissioner for Personal Data, Hong Kong at{' '}
          <a target="_blank" href="https://www.pcpd.org.hk/" rel="noreferrer">
            https://www.pcpd.org.hk/
          </a>{' '}
          for more information on the PDPO and relevant guidance notes.
        </Typography>
        <Typography variant="body-m">
          <strong>OUR PRACTICES</strong>
        </Typography>
        <ol className="privacy-list">
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Types of Personal Data Collected and Held by us</strong>
            </Typography>
            <ol>
              <li>
                <Typography variant="body-m">
                  We may obtain from (I) you; (II) third parties authorised by
                  you; (III) our clinical diagnostic procedures; (IV) your
                  correspondence with us; and (V) your use of our facilities,
                  clinic(s), website, applications, and other platforms, your
                  data and information about you including your personal data,
                  and hold such data and information. &nbsp;Such data and
                  information includes, but is not limited to, the following:
                </Typography>
                <ol>
                  <li>your personal particulars;</li>
                  <li>
                    your contact information (including correspondence address,
                    telephone number, email address and preferred language for
                    communication);
                  </li>
                  <li>
                    personal particulars and contact information of your
                    emergency contact person(s) and/or next-of-kin(s);
                  </li>
                  <li>
                    your credit or debit card account details and billing
                    information (including the name of the cardholder, credit or
                    debit card number and expiry date);
                  </li>
                  <li>
                    your insurance information (including the name of the
                    insurer or broker, insurance policy number and insurance
                    coverage);
                  </li>
                  <li>
                    your medical history / condition, drug history and/or other
                    relevant information relating to your health;
                  </li>
                  <li>
                    information that you provide to us by filling in the blanks
                    on our website, applications and other platforms;
                  </li>
                  <li>
                    your feedback of the products or services; and
                    <br />
                  </li>
                  <li>
                    details of your visits to our website, applications or other
                    platforms, including, but not limited to, traffic data,
                    location data, blogs and other communication data.
                    <br />
                  </li>
                </ol>
              </li>
              <li>
                We may generate and compile additional personal data using the
                above and other information and particulars about you.
              </li>
              <li>
                We may retain a record of the correspondence if you contact us.
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Use of Personal Data</strong>
            </Typography>
            <ol>
              <li>
                <Typography variant="body-m">
                  Your personal data may be used mainly for the following
                  purposes and / or any purposes directly relating to any of
                  them:
                </Typography>
                <ol>
                  <li>
                    <Typography variant="body-m">
                      purposes relating to your healthcare and / or generally
                      for medical care or treatment purposes, including but not
                      limited to the following:{' '}
                    </Typography>
                    <ol>
                      <li>verification of your identity;</li>
                      <li>
                        arrangement and/or provision of healthcare services,
                        medical treatments, and/or facilities, medication and
                        related products (collectively "
                        <strong>Services</strong>") to you;
                      </li>
                      <li>
                        communication with you in relation to arrangement /
                        provision of Services; and
                      </li>
                      <li>
                        processing of payment and/or insurance claims and/or
                        collection of amount(s) due from you;
                      </li>
                    </ol>
                  </li>
                  <li>
                    teaching, education, research and/or statistical purposes by
                    us or any other company which is our subsidiary or holding
                    company or is a fellow subsidiary of such holding company
                    (each an "<strong>Affiliate</strong>" and collectively the "
                    <strong>Affiliates</strong>");
                  </li>
                  <li>
                    operation, usage statistics analysis, improvement, and
                    maintenance of our and our Affiliate’s information systems
                    and our and our Affiliate’s website, applications and
                    platforms for patients, doctors and other healthcare
                    providers;
                  </li>
                  <li>
                    provision of our and/or our Affiliates’ promotional and
                    direct marketing materials to you pursuant to paragraph 4
                    below;
                  </li>
                  <li>
                    evaluation, planning and improvement of services which we
                    and/or our Affiliates provide;
                  </li>
                  <li>communication between you and us or our Affiliates;</li>
                  <li>
                    our or our Affiliates' accreditation, audit, service quality
                    control and administrative purposes;
                  </li>
                  <li>
                    processing, assessing and determining any applications or
                    requests made by you in connection with our services or
                    products;
                    <br />
                  </li>
                  <li>
                    managing and maintaining your account;
                    <br />
                  </li>
                  <li>
                    facilitating third party providers and our Affiliates to
                    contact you and provide assistance to you provided that you
                    have expressed interest in their products or services;
                    <br />
                  </li>
                  <li>
                    investigation and processing of complaints or disputes;
                    <br />
                  </li>
                  <li>
                    prevention or detection of crime;
                    <br />
                  </li>
                  <li>
                    compliance with or disclosure as permitted or required by
                    any law, rule, regulation, code or guideline (collectively "
                    <strong>Law</strong>");
                    <br />
                  </li>
                  <li>
                    analyzing our website (
                    <a
                      target="_blank"
                      href="https://www.bowtiejphealth.com"
                      rel="noreferrer"
                    >
                      https://www.bowtiejphealth.com
                    </a>
                    ), applications and other platforms' usage statistics for
                    the purpose of managing and improving their design and
                    functionalities; <br />
                  </li>
                  <li>
                    provision of after-sales services; and
                    <br />
                  </li>
                  <li>
                    any other purposes as may be agreed between you and us,
                    including purposes set out in any written communication or
                    terms and conditions for the supply of specific
                    services/facilities to you by us.
                    <br />
                  </li>
                </ol>
              </li>
              <li>
                When we collect personal data from you, we will provide you with
                a personal information collection statement ("
                <strong>PICS</strong>") in an appropriate format and manner
                (such as the Personal Information Collection Statement to
                Patients) to identify to you the purposes for which your
                personal data are collected and the types of third parties to
                whom we may disclose your personal data. It is voluntary for you
                to provide your personal data to us. However, if you fail to do
                so, we may not be able to serve the purposes listed above.
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Transfer and Disclosure of Personal Data</strong>
            </Typography>
            <ol>
              <li>
                We will keep your personal data confidential but we may, in
                addition to our staff, transfer or disclose your personal data
                to third parties (such as your attending doctors/healthcare
                providers, your insurer, our Affiliates, our agents and service
                providers or such other persons as permitted by any Law and
                whether within or outside Hong Kong) for the purpose(s) as set
                out in the relevant PICS. We will also disclose your personal
                data as required by any Law.
              </li>
              <li>
                We may transfer or assign the personal data you provided to us
                in the event of a corporate transaction, such as a divestiture,
                merger, consolidation, or asset sale, or in the unlikely event
                of winding up of us.
              </li>
              <li>
                We may transfer or store your personal data at a place outside
                Hong Kong. It may also be processed by staff working inside or
                outside Hong Kong, who work for us, our suppliers or our
                Affiliates. &nbsp;These staff may be engaged in, including but
                not limited to, the fulfillment of activities contemplated in
                and/or ancillary to paragraph 2 above, the processing of your
                payment and provision of after-sales services.
              </li>
              <li>
                Except as stated in paragraphs 3.1 to 3.3 above, we will not
                transfer or disclose your personal data to any third party
                without your prior consent.
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Direct Marketing</strong>
            </Typography>
            <ol>
              <li>
                By virtue of the PDPO, we will not use your personal data for
                direct marketing purposes without your prior consent.
              </li>
              <li>
                Upon receiving your consent, we may use your personal data such
                as your name, mobile phone number and email address to provide
                you with promotional and direct marketing materials in relation
                to healthcare services provided by us or our Affiliates through
                various communication channels such as SMS and email.
              </li>
              <li>
                If you do not wish to receive direct marketing materials from
                us, you may send your request to opt out at any time to
                info@bowtiejphealth.com or through such other channels as we may
                announce from time to time.
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Retention of Personal Data</strong>
            </Typography>
            <ol>
              <li>
                We will not keep your personal data for longer than is necessary
                for fulfilling the purposes (including any directly related
                purposes) for which they are, or are to be, used.
                &nbsp;Different retention periods apply to different types of
                personal data collected and held by us in accordance with our
                retention policies.
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Accuracy and Security of Personal Data</strong>
            </Typography>
            <ol>
              <li>
                We have appropriate procedures in place to maintain, so far as
                is reasonably practicable, the accuracy, completeness and
                relevance of the personal data used by us in relation to the
                purposes for which the personal data are collected.
              </li>
              <li>
                We take appropriate steps to protect the personal data we hold
                against unauthorized or accidental access, processing, erasure,
                loss or use.
              </li>
              <li>
                We have provided relevant training to our employees to handle
                personal data properly and apply encryption to personal data as
                necessary and appropriate. &nbsp; Although we may allow you to
                adjust your privacy settings to limit access to your
                information, no security measures are perfect or impenetrable.
                We cannot control the actions of other users or third parties
                with whom you may choose to share your information. Therefore,
                we cannot and do not guarantee that the personal data you
                provide to us will not be viewed by unauthorized persons.
              </li>
              <li>
                We are not responsible for circumvention of any privacy settings
                or security measures contained on our website by unauthorized
                users.
              </li>
              <li>
                Even after removal, copies of information that you have provided
                to us may remain viewable in cached and archived pages or if
                other users have copied or stored such information.
              </li>
              <li>
                Where you have chosen, or we have given you, a password to
                access certain components of our website, you are responsible
                for keeping such password confidential. We ask you not to share
                such password with anyone. We also exclude all liabilities in
                case you lose such password.
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Cookies</strong>
            </Typography>
            <ol>
              <li>
                Cookies are data files that contain data. Cookies will be stored
                in your computer's hard drive when you browse our website. By
                using our website, you consent to our use of cookies. Such use
                of data does not disclose your personal data, and we will not
                collect, process or use any of your personal data in this
                regard. You can choose not to accept the cookies, but some
                functionalities may not be available if you do so.
              </li>
              <li>
                <Typography variant="body-m">
                  The information collected by means of cookies on our website
                  about you will be used only for the following purposes:
                </Typography>
                <ol>
                  <li>
                    facilitate us in providing a good experience when you browse
                    our website and also allows us to gain insights on how we
                    can further improve our website;
                  </li>
                  <li>
                    provide you with a more personalized experience on our
                    website;
                  </li>
                  <li>remember you when you revisit our website;</li>
                  <li>study browsing behaviors on our website; and</li>
                  <li>
                    obtain information regarding the number of visitors to our
                    website and the pages viewed.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Access and Correction of Personal Data</strong>
            </Typography>
            <ol>
              <li>
                You have the right to request access to and/or correction of
                your personal data held by us. You can make your data access
                and/or correction request in writing to our Data Protection
                Officer by post to Shop B, Ground Floor, China Hong Kong Tower,
                8-12 Hennessy Road, Wan Chai, Hong Kong or email to{' '}
                <a href="mailto:info@bowtiejphealth.com">
                  info@bowtiejphealth.com
                </a>{' '}
                .
              </li>
              <li>
                We will require you or any other person who makes such a request
                on your behalf to provide suitable documents or information for
                verification of identity and/or confirmation of authorization.
              </li>
              <li>
                For any data access request, you may direct such request to us
                at the contact stated at subparagraph 8.1 above or use the form
                specified by the Privacy Commissioner for Personal Data.
              </li>
              <li>
                Any data access request is subject to payment of a reasonable
                fee.
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Enquiries</strong>
            </Typography>
            <ol>
              <li>
                If you have any enquiries on this statement or wish to obtain
                further information on our privacy policies and practices,
                please write to info@bowtiejphealth.com.
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Change to this Privacy Policy Statement</strong>
            </Typography>
            <ol>
              <li>
                We may amend our PICS and Privacy Policy Statement at any time
                without prior notice. Any change or update to our PICS and this
                statement will be effective immediately upon posting on our
                website.
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Language</strong>
            </Typography>
            <ol>
              <li>
                If there is any inconsistency or conflict between the English
                and Chinese versions of this statement, the English version
                shall prevail.
              </li>
            </ol>
          </li>
        </ol>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
