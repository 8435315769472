import { Typography } from '@bowtie-ins/bowkit';
import { INK } from '@bowtie-ins/bowkit/palette';
import { Close, ShieldSecurity } from '@bowtie-ins/bowkit/icons';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { Container } from './PrivacyPolicy';

const PrivacyPolicyZh: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box py={2} px={3}>
      <Box display="flex" justifyContent="space-between">
        <ShieldSecurity style={{ color: INK }} />
        <IconButton onClick={onClose}>
          <Close style={{ color: INK }} />
        </IconButton>
      </Box>
      <Container>
        <Typography variant="headline-s">私隱政策聲明</Typography>
        <Typography variant="body-m">
          Bowtie x JP Partners Medical Centre Limited（「<strong>我們</strong>
          」或「<strong>我們的</strong>
          」）認為患者、我們的服務使用者、設施和診所的訪客，以及我們網站（{' '}
          <a href="https://www.bowtiejphealth.com/">
            https://www.bowtiejphealth.com/
          </a>
          ）、應用程式和其他平台的訪客和使用者（「<strong>你</strong>」或「
          <strong>你的</strong>
          」）的私隱非常重要。本聲明列明我們如何透過你使用我們的服務、參與我們的臨床診斷程序、到訪我們的設施和診所、瀏覽及使用我們的網站、應用程式和其他平台，收集你的個人資料，以及我們如何使用或披露這些個人資料。
        </Typography>
        <Typography variant="body-m">
          <strong>我們的政策</strong>
        </Typography>
        <Typography variant="body-m">
          我們致力遵守《個人資料（私隱）條例》（第 486 章）（「
          <strong>私隱條例</strong>
          」）的規定，以保障我們收集和持有的個人資料的私隱、保密和安全。請瀏覽香港個人資料私隱專員公署網站
          http://www.pcpd.org.hk 以了解更多有關私隱條例和相關指引的資料。
        </Typography>
        <Typography variant="body-m">
          <strong>我們的慣常做法</strong>
        </Typography>

        <ol className="privacy-list">
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>我們收集和持有的個人資料類型</strong>
            </Typography>
            <ol>
              <li>
                <Typography variant="body-m">
                  我們可能會從（I）你；（II）你授權的第三方；（III）我們的臨床診斷程序；（IV）你與我們的通訊；及（V）你對我們的設施、診所、網站、應用程式和平台的使用，收集你的個人資料和有關你的資料，包括你的個人資料，並持有這些資料。
                  這些資料包括但不限於：
                </Typography>
                <ol>
                  <li>你的個人資料；</li>
                  <li>
                    你的聯絡資料（包括通訊地址、電話號碼、電子郵件地址和偏好通訊語言）；
                  </li>
                  <li>你的緊急聯絡人和/或家屬的個人資料和聯絡資料；</li>
                  <li>
                    你的信用卡或儲值卡賬戶資料和賬單資料（包括持卡人姓名、信用卡或儲值卡號碼和到期日）；
                  </li>
                  <li>
                    你的保險資料（包括保險公司或經紀人的名稱、保險單編號和保障範圍）；
                  </li>
                  <li>
                    你的病歷/病況、用藥紀錄和/或其他與你的健康相關的資料；
                  </li>
                  <li>
                    你在我們的網站、應用程式和其他平台上輸入並提供給我們的資料；
                  </li>
                  <li>
                    你對我們產品或服務的意見；及<br></br>
                  </li>
                  <li>
                    你瀏覽我們的網站、應用程式和其他平台的詳細資料，包括但不限於流量數據、位置數據、網誌和其他通訊資料。
                    <br></br>
                  </li>
                </ol>
              </li>
              <li>
                我們可能會使用上述和其他有關你的資料和詳情來編製更多個人資料。
              </li>
              <li>如果你與我們聯絡，我們可能會保留通訊記錄。</li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>個人資料的使用</strong>
            </Typography>
            <ol>
              <li>
                <Typography variant="body-m">
                  你的個人資料可能被主要用於以下目的和/或與其中任何一項目的直接相關的目的：
                </Typography>
                <ol>
                  <li>
                    <Typography variant="body-m">
                      與你的醫療保健和/或一般醫療護理或治療有關的目的，包括但不限於：
                    </Typography>
                    <ol>
                      <li>驗證你的身份；</li>
                      <li>
                        向你安排和/或提供醫療保健服務、醫療和/或設施、藥物和相關產品（統稱為「
                        <strong>該服務</strong>」）；
                      </li>
                      <li>與你就安排/提供該服務進行溝通；及</li>
                      <li>處理付款、保險索償和/或收取你的應付款項；</li>
                    </ol>
                  </li>
                  <li>
                    我們或我們的子公司、控股公司、該控股公司的子公司（統稱為「
                    <strong>關聯公司</strong>
                    」）的教學、教育、研究和/或統計目的；
                  </li>
                  <li>
                    我們和我們的關聯公司的資料系統，以及其為患者、醫生和其他醫療保健供應商而設的網站、應用程式和平台的營運、使用統計分析、改進和維持；
                  </li>
                  <li>
                    根據以下第 4
                    段向你提供我們和/或我們的關聯公司的推廣和直接促銷資料；
                  </li>
                  <li>評估、規劃和改進我們和/或我們的關聯公司提供的服務；</li>
                  <li>你與我們或我們的關聯公司之間的溝通；</li>
                  <li>
                    我們或我們的關聯公司的認證、審計、服務質量控制和行政目的；
                  </li>
                  <li>
                    處理、評估和決定你提出與我們的服務或產品有關的任何申請或請求；2.1.9.
                    管理和維持你的帳戶；<br></br>
                  </li>
                  <li>
                    管理和維持你的帳戶；<br></br>
                  </li>
                  <li>
                    協助第三方供應商和我們的關聯公司聯絡你並向你提供協助，前提是你已對他們的產品或服務表示有興趣；
                    <br></br>
                  </li>
                  <li>
                    調查和處理投訴或糾紛；<br></br>
                  </li>
                  <li>
                    預防或偵查罪案<br></br>
                  </li>
                  <li>
                    按法律、規則、法規、守則或準則（統稱為「
                    <strong>法律</strong>」）允許或要求而作出披露；<br></br>
                  </li>
                  <li>
                    分析我們的網站（
                    <a href="http://www.bowtiejphealth.com">
                      www.bowtiejphealth.com
                    </a>
                    ）、應用程式和其他平台的使用統計數據，以管理和改進其設計和功能；
                    <br></br>
                  </li>
                  <li>
                    提供售後服務；及<br></br>
                  </li>
                  <li>
                    你與我們之間可能同意的任何其他目的，包括在任何書面通訊中列明的或在我們向你提供特定服務/設施的條款和條件中列明的目的。
                    <br></br>
                  </li>
                </ol>
              </li>
              <li>
                當我們向你收集個人資料時，我們會以適當的格式和方式向你提供個人資料收集聲明（「
                <strong>收集聲明</strong>
                」），例如「給患者的個人資料收集聲明」，以向你說明收集你的個人資料的目的，以及我們可能披露你的個人資料予什麼類別的第三方。向我們提供你的個人資料是自願性的。但是，如果你未能提供所需資料，我們可能無法達到上述目的。
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>個人資料的轉移及披露</strong>
            </Typography>
            <ol>
              <li>
                我們將對你的個人資料保密，但除我們的員工外，我們還可能將你的個人資料轉移或披露給第三方（例如你的主診醫生/醫療保健供應商、你的保險公司、我們的關聯公司、我們的代理和服務供應商或受法律允許的其他人士，無論他們在香港境內還是境外），以達到相關收集聲明規定的目的。我們亦會根據任何法律的要求披露你的個人資料。
              </li>
              <li>
                如果發生企業交易，例如資產分拆、合併、整合或資產出售，或者在不太可能發生的公司清盤情況下，我們可能會轉移或轉讓你提供給我們的個人資料。
              </li>
              <li>
                我們可能會將你的個人資料轉移或儲存在香港以外的地方。你的個人資料也可能由在香港境內或境外為我們、我們的供應商或我們的關聯公司工作的員工處理。這些員工可能從事包括但不限於上文第
                2
                段所述的活動和/或其相關的活動、你的付款的處理和售後服務的提供。
              </li>
              <li>
                我們會在向在上述第 3.1 至第 3.3
                段所列以外的各方轉移或披露你的個人資料之前先取得你的同意。
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>直接促銷</strong>
            </Typography>
            <ol>
              <li>
                根據私隱條例，未經你事先同意，我們不會將你的個人資料用於直接促銷目的。
              </li>
              <li>
                在取得你的同意後，我們可能會使用你的姓名、手機號碼和電子郵件地址等個人資料，透過各種溝通渠道(例如短信和電子郵件)向你提供與我們或我們的關聯公司的醫療保健服務相關的推廣和直接促銷資料。
              </li>
              <li>
                如果你不希望收到我們的直接促銷資料，你可以隨時將選擇退出的請求發送至
                info@bowtiejphealth.com 或我們可能不時宣布的其他渠道。
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>個人資料的保留</strong>
            </Typography>
            <ol>
              <li>
                我們保留你的個人資料的時間不會超過為達到使用或將要使用它們的目的（包括任何直接相關的目的）所需的時間。根據我們的保留政策，不同的保留期限適用於我們收集和持有的不同類型的個人資料。
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>個人資料的準確性和安全性</strong>
            </Typography>
            <ol>
              <li>
                我們有適當的程序在合理可行的情況下，維持我們為達到目的而收集和使用的個人資料的準確性、完整性和相關性。
              </li>
              <li>
                我們採取適當措施保護我們持有的個人資料，免遭未經授權或意外的存取、處理、刪除、丟失或使用。
              </li>
              <li>
                我們已向我們的員工提供相關培訓，以正確處理個人資料，並在必要和適當時加密個人資料。儘管我們可能允許你調整私隱設置，以限制資料存取，沒有任何安全措施是完美或無懈可擊的，我們無法控制你可能選擇與之分享資料的其他使用者或第三方的行為。因此，我們不能也不保證你提供給我們的個人資料不會被未經授權的人查看。
              </li>
              <li>
                對於未經授權的使用者規避我們網站上包含的任何私隱設置或安全措施，我們概不負責。
              </li>
              <li>
                即使在刪除你提供給我們的資料後，如果其他使用者已複製或儲存該些資料，該些資料的副本仍可在緩存和存檔頁面中被查看。
              </li>
              <li>
                如果你選擇了密碼，或我們向你提供了瀏覽我們網站某些部分的密碼，你有責任保密這密碼，不要與任何人共享這密碼。如果你丟失這密碼，我們概不負責。
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>Cookies</strong>
            </Typography>
            <ol>
              <li>
                Cookies 是包含資料的檔案。當你瀏覽我們的網站時，cookies
                將儲存在你的電腦硬盤中。使用我們的網站，即表示你同意我們使用
                cookies。
                使用這些資料不會披露你的個人資料，我們不會在這方面收集、處理或使用你的任何個人資料。你可以選擇不接受
                cookies，但如果你這樣做，可能無法使用某些功能。
              </li>
              <li>
                <Typography variant="body-m">
                  我們網站上通過 cookies 收集有關你的資料將僅用於以下目的：
                </Typography>
                <ol>
                  <li>
                    幫助我們為你提供良好的瀏覽網站體驗，並讓我們了解如何進一步改善我們的網站
                    ；
                  </li>
                  <li>為你在我們的網站上提供更加個人化的體驗；</li>
                  <li>當你再次瀏覽我們的網站時可以記得你；</li>
                  <li>研究在我們網站上的瀏覽行為；及</li>
                  <li>獲取有關我們網站的瀏覽者數量和查看頁面的資料。</li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>查閱及更正個人資料</strong>
            </Typography>
            <ol>
              <li>
                你有權要求查閱和/或更正我們持有的你的個人資料。你可以郵寄至香港灣仔軒尼詩道8-12號中港大廈地下B鋪或電郵至
                <a href="mailto:info@bowtiejphealth.com">
                  info@bowtiejphealth.com
                </a>
                ，以書面形式向我們的資料保護主任提出查閱及/或更正資料要求。
              </li>
              <li>
                我們將要求你或代表你提出此要求的任何其他人提供合適的文件或資料以驗證身份和/或確認授權。
              </li>
              <li>
                對於任何資料查閱請求，你可以通過上文第 8.1
                段所述的聯絡方式向我們提出請求，或使用個人資料私隱專員指定的表格。
              </li>
              <li>任何資料查閱請求均需支付合理費用。</li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>查詢</strong>
            </Typography>
            <ol>
              <li>
                如果你對本聲明有任何疑問或希望獲得更多有關我們的私隱政策和慣例的資料，請電郵至
                info@bowtiejphealth.com。
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>本私隱政策聲明的修改</strong>
            </Typography>
            <ol>
              <li>
                我們可能隨時修改我們的個人資料收集聲明和私隱政策聲明，而不作事先通知。我們的個人資料收集聲明和私隱政策聲明的任何更改或更新，將在我們的網站上發布後立即生效。
              </li>
            </ol>
          </li>
          <li className="static--list-parts">
            <Typography variant="body-m">
              <strong>語言</strong>
            </Typography>
            <ol>
              <li>
                如本聲明的中英文版本有任何不一致或分歧，概以英文版本為準。
              </li>
            </ol>
          </li>
        </ol>
      </Container>
    </Box>
  );
};

export default PrivacyPolicyZh;
