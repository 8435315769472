import { HOTEL_CALIFORNIA, WHITE } from '@bowtie-ins/bowkit/palette';
import styled from '@emotion/styled';
import { Alert as BaseAlert, AlertProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { colours } from 'src/constants';
import { TickCircle } from '@bowtie-ins/bowkit/icons';

const Component = (props: AlertProps) => {
  switch (props.severity) {
    case 'success':
      return (
        <BaseAlert {...props} icon={<TickCircle fontSize="inherit" />}>
          {props.children}
        </BaseAlert>
      );
    default:
      return <BaseAlert {...props}>{props.children}</BaseAlert>;
  }
};

const Alert = styled(Component)<{ severity: string; theme?: Theme }>(
  ({ severity, theme }) => ({
    borderRadius: +theme.shape.borderRadius * 5,
    paddingLeft: theme.spacing(1.875),
    color: WHITE,
    backgroundColor: colours.PRIMARY,
    '.MuiAlert-icon': {
      color: WHITE,
      marginRight: theme.spacing(0.75),
    },
    '.MuiAlert-message': {
      padding: '9px 0',
    },
    ...(severity === 'success' && {
      backgroundColor: colours.PRIMARY,
    }),
    ...(severity === 'info' && {
      backgroundColor: colours.PRIMARY,
    }),
    ...(severity === 'error' && {
      backgroundColor: HOTEL_CALIFORNIA,
    }),
  }),
);

export default Alert;
