import { Typography } from '@bowtie-ins/bowkit';
import { Phone } from '@bowtie-ins/bowkit/icons';
import { Box } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecaptcha, useRequestLoginOtp } from 'src/auth/hooks';
import Alert from 'src/components/Alert';
import Button from 'src/components/Button';
import { useQs } from 'src/utils/routing';
import { object, string } from 'yup';
import TextField from './TextField';

const LoginForm: React.FC<{ userId?: string }> = ({ userId }) => {
  const { t, i18n } = useTranslation(['auth', 'common']);
  const { verify } = useRecaptcha('login');

  const [loading, setLoading] = useState(false);
  const qs = useQs();

  const initialValues = {
    phone_number: qs?.phone_number || '',
    recaptcha_token: '',
  };

  type LoginProps = {
    phone_number: string;
  };

  const { otpForm, setOtpForm, requestLoginOtp, otpMedium, errorCode } =
    useRequestLoginOtp(initialValues);

  const validationSchema = () => {
    return object({
      // FIXME: remove the 2nd part of regex upon production launch
      phone_number: string().matches(/^([2-9][0-9]{7}|\+[0-9]{0,14})$/, {
        message: 'invalid.phone_number.format',
        excludeEmptyString: true,
      }),
    });
  };

  const handleSubmit = async (
    _: LoginProps,
    helper: FormikHelpers<LoginProps>,
  ) => {
    setLoading(true);
    const token = await verify();
    const [submitted] = await Promise.all([
      requestLoginOtp(i18n.language, token, userId),
      new Promise(r => setTimeout(r, 2000)),
    ]);
    if (submitted) {
      helper.resetForm();
    }
    setLoading(false);
  };

  return (
    <Formik<LoginProps>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setOtpForm({
            ...otpForm,
            [event.target.name]: event.target.value,
          });
          setFieldValue(event.target.name, event.target.value);
        };

        return (
          <Form noValidate>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="100%" p={2.75} display="grid" gap={1}>
                {!loading && otpMedium && (
                  <Box mb={2}>
                    <Alert severity="success">
                      <Typography variant="title-s">
                        {t('login.success.title')}
                      </Typography>
                      <Typography variant="body-m">
                        {t('login.success.desc', { medium: otpMedium })}
                      </Typography>
                    </Alert>
                  </Box>
                )}
                {!loading && errorCode && (
                  <Box mb={2}>
                    <Alert severity="error">
                      <Typography variant="body-m">
                        {t(errorCode.type, errorCode.message)}
                      </Typography>
                    </Alert>
                  </Box>
                )}
                {!userId && (
                  <TextField
                    autoFocus
                    type="tel"
                    name="phone_number"
                    label={t('common.phone', { ns: 'common' })}
                    icon={<Phone />}
                    onChange={handleChange}
                    value={values.phone_number}
                    errorMessage={
                      touched.phone_number ? errors.phone_number : ''
                    }
                  />
                )}
                <Box mt={2}>
                  <Button
                    size="s"
                    type="submit"
                    fullWidth
                    loading={loading}
                    disabled={
                      loading ||
                      (!values.phone_number && !userId) ||
                      !!(userId && otpMedium)
                    }
                  >
                    {!loading && t('login.request_login_link')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
