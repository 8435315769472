import { Typography } from '@bowtie-ins/bowkit';
import { DiagnosticImaging, Phone } from '@bowtie-ins/bowkit/icons';
import { Box, ToggleButton } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecaptcha, useSignUp } from 'src/auth/hooks';
import { Alert, Button, ToggleButtonGroup } from 'src/components';
import { useQs } from 'src/utils/routing';
import { object, string } from 'yup';
import { LANGUAGE } from 'src/constants';
import TextField from './TextField';

/**
 *
 * error {"__type":"UserLambdaValidationException","message":"PreSignUp failed with error {\"code\":\"PlatoUserMismatch\",\"message\":\"No user with the supplied plato given id found matching user's phone number.\"}."}
 */

const RegisterForm: React.FC = () => {
  const { t, i18n } = useTranslation(['auth', 'common']);
  const { verify } = useRecaptcha('register');
  const [loading, setLoading] = useState(false);
  const qs = useQs();

  const initialValues = {
    phone_number: qs?.phone_number || '',
    plato_given_id: qs?.plato_given_id || '',
    language: i18n.language as LANGUAGE,
    recaptcha_token: '',
  };

  type RegisterProps = {
    phone_number: string;
    plato_given_id: string;
    language: LANGUAGE;
  };

  const { form, setForm, signUp, errorCode, otpMedium } =
    useSignUp(initialValues);

  const validationSchema = () => {
    return object({
      // FIXME: remove the 2nd part of regex upon production launch
      phone_number: string().matches(/^([2-9][0-9]{7}|\+[0-9]{0,14})$/, {
        message: 'invalid.phone_number.format',
        excludeEmptyString: true,
      }),
      plato_given_id: string(),
    });
  };

  const handleSubmit = async (
    _: RegisterProps,
    helper: FormikHelpers<RegisterProps>,
  ) => {
    setLoading(true);
    const token = await verify();
    const [submitted] = await Promise.all([
      signUp(token),
      new Promise(r => setTimeout(r, 2000)),
    ]);
    if (submitted) {
      helper.resetForm();
    }
    setLoading(false);
  };

  return (
    <Formik<RegisterProps>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setForm({
            ...form,
            [event.target.name]: event.target.value,
          });
          setFieldValue(event.target.name, event.target.value);
        };

        const handleLanguageChange = (
          event: React.MouseEvent<HTMLElement, MouseEvent>,
          value: any,
        ) => {
          setForm({
            ...form,
            language: value as string,
          });
          setFieldValue('language', value);
        };

        return (
          <Form noValidate>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="100%" p={2.75} display="grid" gap={1}>
                {!loading && otpMedium && (
                  <Box mb={2}>
                    <Alert severity="success">
                      <Typography variant="title-s">
                        {t('register.success.title')}
                      </Typography>
                      <Typography variant="body-m">
                        {t('register.success.desc', { medium: otpMedium })}
                      </Typography>
                    </Alert>
                  </Box>
                )}
                {!loading && errorCode && (
                  <Box mb={2}>
                    <Alert severity="error">
                      <Typography variant="body-m">
                        {t(errorCode.type, errorCode.message)}
                      </Typography>
                    </Alert>
                  </Box>
                )}
                <TextField
                  autoFocus
                  type="tel"
                  name="phone_number"
                  label={t('common.phone', { ns: 'common' })}
                  icon={<Phone />}
                  onChange={handleChange}
                  value={values.phone_number}
                  errorMessage={touched.phone_number ? errors.phone_number : ''}
                />
                <TextField
                  name="plato_given_id"
                  label={t('common.plato_given_id', { ns: 'common' })}
                  pattern="\d*"
                  icon={<DiagnosticImaging />}
                  onChange={handleChange}
                  value={values.plato_given_id}
                  errorMessage={
                    touched.plato_given_id ? errors.plato_given_id : ''
                  }
                />
                <Typography variant="body-m" fontWeight="medium">
                  {t('register.lang.preference')}
                </Typography>
                <ToggleButtonGroup
                  value={values.language}
                  onChange={handleLanguageChange}
                >
                  <ToggleButton value="zh">
                    <Typography variant="body-l" fontWeight="bold">
                      {t('common.chinese', { ns: 'common' })}
                    </Typography>
                  </ToggleButton>
                  <ToggleButton value="en">
                    <Typography variant="body-l" fontWeight="bold">
                      {t('common.english', { ns: 'common' })}
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
                <Box mt={2}>
                  <Button
                    size="s"
                    type="submit"
                    fullWidth
                    loading={loading}
                    disabled={
                      loading || !values.phone_number || !values.plato_given_id
                    }
                  >
                    {!loading && t('register.register')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
export default RegisterForm;
