import { WHITE } from '@bowtie-ins/bowkit/palette';
import styled from '@emotion/styled';
import { ToggleButtonGroup as BaseToggleButtonGroup } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { transparentize } from 'polished';
import React from 'react';
import { colours } from 'src/constants';

const CustomToggleButtonGroup = styled(BaseToggleButtonGroup)<{
  theme?: Theme;
}>(({ theme }) => ({
  boxShadow: '0px 4px 20px -4px #00000038',
  height: 60,
  background: WHITE,
  borderRadius: +theme.shape.borderRadius * 2,
  button: {
    borderRadius: +theme.shape.borderRadius * 2,
    textTransform: 'none',
    background: WHITE,
    '&:hover': {
      background: transparentize(0.8, colours.PRIMARY),
    },
    '&.Mui-selected': {
      borderColor: colours.PRIMARY,
      borderWidth: 2,
    },
    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
      marginLeft: -2,
      borderLeft: `2px solid ${colours.PRIMARY}`,
    },
  },
}));

const ToggleButtonGroup: React.FC<{
  value: string;
  onChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => void;
  children: React.ReactElement | React.ReactElement[];
}> = ({ value, onChange, children, ...props }) => {
  return (
    <CustomToggleButtonGroup
      value={value}
      color="primary"
      onChange={onChange}
      exclusive
      fullWidth
      {...props}
    >
      {children}
    </CustomToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
