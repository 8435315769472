import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colours } from 'src/constants';

const LayoutBox = styled(Box)({
  minHeight: ['100vh', 'var(--doc-height)'],
  minWidth: '100vw',
  backgroundColor: colours.BACKGROUND,
});

export default LayoutBox;
