import dayjs from 'dayjs';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { en, zh } from 'src/locale';

const resources = {
  en,
  zh,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: {
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'lng',
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safe from xss
      format: (value, format) => {
        if (value instanceof Date) {
          return dayjs(value).format(format);
        }
        return value;
      },
    },
    fallbackLng: {
      default: ['zh'],
    },
  });

export default i18n;
