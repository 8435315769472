import { Auth } from '@aws-amplify/auth';
import { CurrentUserOpts } from '@aws-amplify/auth/lib-esm/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAsyncFn, usePrevious } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsync';
import { User, UserChangeCallback } from 'src/auth/types';
import { createContext } from 'src/utils/react';

type Value = {
  signOut: () => Promise<void>;
  verifyState: AsyncState<User | undefined>;
  verify: (params?: CurrentUserOpts) => Promise<User | undefined>;
  updatingAuthState: boolean;
} & (
  | {
      authenticated: true;
      user: User;
    }
  | {
      authenticated: false;
      user: undefined;
    }
);

const [useAuth, AuthContext] = createContext<Value>();

const useAuthenticated = () => {
  const { authenticated } = useAuth();
  return authenticated;
};

const useUser = () => {
  const { user } = useAuth();
  return user;
};

const AuthConsumer = AuthContext.Consumer;

const useUserChange = (
  user: User | undefined,
  callback?: UserChangeCallback,
) => {
  const prevUser = usePrevious(user);
  const callbackRef = useRef(callback);

  // Save callback to ref
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Immunize against callback change
  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(user, prevUser);
    }
  }, [prevUser, user]);
};

type Props = {
  children: React.ReactNode;
  onUserChange?: UserChangeCallback;
  FallbackComponent?: React.ComponentType;
};

const AuthProvider = ({ children, onUserChange, FallbackComponent }: Props) => {
  const [verifyState, verify] = useAsyncFn<
    (params?: CurrentUserOpts) => Promise<User | undefined>
  >(async (params: CurrentUserOpts = { bypassCache: true }) => {
    try {
      const user = await Auth.currentAuthenticatedUser(params);
      return user;
    } catch (err) {
      return undefined;
    }
  });

  const [initialized, setInitialized] = useState(false);
  const [updatingAuthState, setUpdatingAuthState] = useState(false);

  const user = useMemo(() => verifyState.value, [verifyState]);

  useUserChange(user, onUserChange);

  useEffect(() => {
    verify({ bypassCache: true }).then(() => setInitialized(true));
  }, [verify]);

  const value = useMemo(() => {
    const signOut = async () => {
      if (user) {
        setUpdatingAuthState(true);
        user.signOut();
        await verify({ bypassCache: true });
        setUpdatingAuthState(false);
      }
    };

    // explicitly type hinting for common code branching pattern
    const userObj =
      user === undefined
        ? ({
            user: undefined,
            authenticated: false,
          } as const)
        : ({
            user,
            authenticated: true,
          } as const);

    return {
      ...userObj,
      verifyState,
      verify,
      signOut,
      updatingAuthState,
    };
  }, [user, verifyState, verify, updatingAuthState]);

  if (!initialized) {
    if (FallbackComponent) {
      return <FallbackComponent />;
    }
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export {
  Auth,
  useAuth,
  useAuthenticated,
  useUser,
  AuthContext,
  AuthProvider,
  AuthConsumer,
};
export type { User, UserChangeCallback };
