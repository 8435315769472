import { Typography } from '@bowtie-ins/bowkit';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentHead from 'src/components/DocumentHead';
import { Layout, RegisterForm } from 'src/features/auth/components';

const Register = () => {
  const { t } = useTranslation('auth');
  return (
    <Layout>
      <DocumentHead titleKey="meta_title.register" />
      <Box textAlign="center" maxWidth={250} m="auto">
        <Typography variant="headline-s">{t('register.title')}</Typography>
      </Box>
      <RegisterForm />
    </Layout>
  );
};

export default Register;
