import { Path } from './path';
import colours from './colours';

export enum Environment {
  PullRequest = 'pull-request',
  Development = 'development',
  Production = 'production',
}

export const HEADER_HEIGHT = 60;

export const BASENAME = process.env.REACT_APP_BASENAME || '';

export const RECAPTCHA_V3_KEY = process.env.REACT_APP_RECAPTCHA_V3_KEY || '';

export const AUTH_ERROR_CODES = {
  EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
  USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
  USER_LAMBDA_VALIDATION_EXCEPTION: 'UserLambdaValidationException',
};

export enum LANGUAGE {
  EN = 'en',
  ZH = 'zh',
}

export { Path, colours };
