import { Button } from '@mui/material';
import styled from '@emotion/styled';
import { Link, LinkProps } from 'react-router-dom';
import React from 'react';

const CustomerButton = styled(Button)({
  minWidth: 'unset',
  padding: 0,
  '&:hover': {
    background: 'none',
  },
}) as typeof Link;

const LinkButton: React.FC<LinkProps> = ({ children, ...props }) => (
  <CustomerButton component={Link} {...props}>
    {children}
  </CustomerButton>
);

export default LinkButton;
