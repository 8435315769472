import { Typography } from '@bowtie-ins/bowkit';
import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { Avatar } from 'src/components';
import { useMe } from 'src/features/account/hooks';

const UserInfo: React.FC = () => {
  const [me] = useMe();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Avatar size={50} />
      <Box mt={1}>
        <Typography variant="title-l">
          {me ? me.identity.english_name : <Skeleton width={150} />}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserInfo;
