import { IParseOptions, parse } from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { BASENAME } from 'src/constants';

export const parseQs = (search: string, options?: IParseOptions) => {
  const merged = {
    ignoreQueryPrefix: true,
    ...options,
  } as IParseOptions;
  return parse(search, merged);
};

export function useQs<T = { [key: string]: any }>(options?: IParseOptions) {
  const { search } = useLocation();

  const parsed: T = useMemo(() => {
    // It's caller's responsibility to ensure the given type generic is sensible
    return parseQs(search, options) as any;
  }, [search, options]);

  return parsed;
}

// Rewriting URLs that are used eternally (e.g. callback) for PR sites
export const prependBasePath = (pathname: string): string => {
  return `${BASENAME}${pathname}`;
};
