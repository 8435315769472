import { gql } from '@apollo/client';

export type ReportMedia = {
  pk: string;
  sk: string;
  effective_at: string;
  issued_at: string;
  type: string;
  content_type: string;
};

export type OriginalReport = {
  content_type: string;
  source: string;
};

export type Report = {
  category: string;
  name: string;
  status: string;
  pk: string;
  sk: string;
  effective_at: string;
  issued_at: string;
  media: ReportMedia;
  original_report: OriginalReport;
};

export const LIST_REPORTS = gql`
  query Reports {
    reports @rest(type: "Report", path: "/report") {
      __typename
      category
      name
      status
      pk
      sk
      effective_at
      issued_at
      media
      original_report
    }
  }
`;
