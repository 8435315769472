import { Typography } from '@bowtie-ins/bowkit';
import { WHITE } from '@bowtie-ins/bowkit/palette';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colours, LANGUAGE } from 'src/constants';
import { ReportDayItem } from 'src/features/report/components';
import { Report } from 'src/graphql';

const ReportDayList: React.FC<{ date: string; reports: Report[] }> = ({
  date,
  reports,
}) => {
  const { i18n } = useTranslation('report');

  const getFormattedDateStr = (dateStr: string) => {
    const date = new Date(dateStr);
    if (i18n.language === 'en') {
      return dayjs(date).format('MMM D (ddd)');
    } else if (i18n.language === 'zh') {
      return `${date
        .toLocaleDateString(i18n.language, {
          month: 'short',
          day: 'numeric',
        })
        .replace(/(\D)/g, ' $1 ')} (${
        date.toLocaleDateString(i18n.language, {
          weekday: 'short',
        })[1]
      })`;
    }
    return `${date.toLocaleDateString(i18n.language, {
      month: 'short',
      day: 'numeric',
    })} (${date.toLocaleDateString(i18n.language, { weekday: 'short' })})`;
  };

  return (
    <Box mb={2}>
      <Box
        bgcolor={colours.PRIMARY}
        color={WHITE}
        display="inline-block"
        px={1.25}
        borderRadius={2}
        style={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Typography
          fontWeight={i18n.language === LANGUAGE.ZH ? 'medium' : 'bold'}
          variant="title-m"
        >
          {getFormattedDateStr(date)}
        </Typography>
      </Box>
      <Box
        bgcolor={WHITE}
        borderRadius={2}
        style={{
          borderTopLeftRadius: 0,
        }}
      >
        {reports.map((report, i, reportList) => (
          <ReportDayItem
            key={report.pk}
            report={report}
            first={i === 0}
            last={i === reportList.length - 1}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ReportDayList;
