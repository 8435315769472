import { gql } from '@apollo/client';

export type Patient = {
  identity: {
    english_name: string;
    chinese_name: string;
    gender: string;
    date_of_birth: string;
  };
  user: {
    id: string;
    type: string;
    email: string;
    phone_number: string;
  };
};

export const RETRIEVE_PATIENT_ME = gql`
  query Me {
    me @rest(type: "Patient", path: "/patient/me") {
      __typename
      identity
      user
    }
  }
`;

export const CONFIRM_ACCOUNT = gql`
  mutation Me {
    me(input: $input)
      @rest(type: "Patient", path: "/patient/confirm", method: "POST") {
      __typename
    }
  }
`;
