import React from 'react';
import DocumentHead from 'src/components/DocumentHead';
import { Layout, LoginConfirmVerification } from 'src/features/auth/components';

const LoginConfirmation = () => {
  return (
    <Layout>
      <DocumentHead titleKey="meta_title.login" />
      <LoginConfirmVerification />
    </Layout>
  );
};

export default LoginConfirmation;
