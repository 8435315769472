import { Box } from '@mui/material';
import React from 'react';
import { Layout } from 'src/components';
import DocumentHead from 'src/components/DocumentHead';
import { AccountMenu, UserInfo } from 'src/features/account/components';

const AccountDetail: React.FC = () => {
  return (
    <Layout>
      <DocumentHead titleKey="meta_title.account" />
      <Box p={4}>
        <UserInfo />
      </Box>
      <AccountMenu />
    </Layout>
  );
};

export default AccountDetail;
