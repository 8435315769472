// bowtie-ins/bowtie-admin-website:src/components/InternalLink.tsx
import { useMemo } from 'react';
import {
  generatePath,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  IconButton,
  Link as MuiLink,
  LinkTypeMap as MuiLinkTypeMap,
} from '@mui/material';

type InternalLinkProps = MuiLinkTypeMap<
  Omit<RouterLinkProps, 'to'>
>['props'] & {
  openInCurrentTab?: boolean;
  to:
    | string
    | {
        path: string;
        params?: { [paramName: string]: string | number | boolean | undefined };
      };
};

/**
 * Link component for internal page.
 */
function InternalLink({
  children,
  openInCurrentTab = true,
  to: toProp,
  ...rest
}: InternalLinkProps) {
  const to = useMemo(
    () =>
      typeof toProp === 'string'
        ? generatePath(toProp)
        : generatePath(toProp.path, toProp.params),
    [toProp],
  );

  return (
    <MuiLink
      display="inline-flex"
      alignItems="center"
      component={RouterLink}
      to={to}
      {...(openInCurrentTab
        ? undefined
        : {
            target: '_blank',
            rel: 'noopener noreferrer',
          })}
      {...rest}
    >
      {children}
      {!openInCurrentTab ? (
        <IconButton color="primary" size="small">
          <OpenInNewIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiLink>
  );
}

export default InternalLink;
