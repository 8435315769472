import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { colours } from 'src/constants';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: colours.PRIMARY,
    },
    secondary: {
      main: colours.SECONDARY,
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
