/**
 * bowtiejp's own theme colors
 */

const colours = {
  BACKGROUND: '#F3E7DC',
  SECONDARY: '#F7AA95',
  PRIMARY: '#125769',
};

export default colours;
