import { Typography } from '@bowtie-ins/bowkit';
import {
  DRAGON_FRUIT,
  GRAPHITE,
  ROCK,
  WHITE,
} from '@bowtie-ins/bowkit/palette';
import styled from '@emotion/styled';
import { Box, BoxProps, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseInputMask from 'react-input-mask';

const inputStyle = {
  fontSize: '1rem',
  fontWeight: 'inherit',
  color: GRAPHITE,
  background: 'transparent',
  border: 'none',
  outline: 'none',
  width: '100%',
  padding: 0,
  '::placeholder': {
    color: ROCK,
  },
};

const Input =
  styled.input<React.InputHTMLAttributes<HTMLInputElement>>(inputStyle);
const InputMask =
  styled(BaseInputMask)<React.InputHTMLAttributes<HTMLInputElement>>(
    inputStyle,
  );

type ContainerProps = BoxProps & {
  isFocused: boolean;
  hasError?: string;
  theme?: Theme;
};

const UnstyledContainer: React.FC<ContainerProps> = ({
  isFocused,
  hasError,
  ...props
}) => <Box {...props}></Box>;

const Container = styled(UnstyledContainer)<ContainerProps>(
  ({ isFocused, hasError, theme }) => ({
    display: 'flex',
    boxShadow: '0px 4px 20px -4px #00000038',
    alignItems: 'center',
    cursor: 'text',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    width: '100%',
    height: 64,
    background: WHITE,
    borderRadius: +theme.shape.borderRadius * 2,
    '> div': {
      transition: 'filter 0.2s ease-out',
      filter: isFocused ? 'brightness(0.5)' : 'brightness(1)',
    },
    '&:hover > div': {
      filter: 'brightness(0.5)',
    },
    ...(hasError && {
      filter:
        'grayscale(100%) brightness(75%) sepia(100%) hue-rotate(-50deg) saturate(350%) contrast(0.8)',
    }),
  }),
);

const UnstyledLabelBox: React.FC<BoxProps & { enlarge: boolean }> = ({
  enlarge,
  ...props
}) => <Box {...props}></Box>;

const LabelBox = styled(UnstyledLabelBox)(({ enlarge }) => ({
  transition: 'transform 0.15s ease-out',
  transformOrigin: 'top left',
  pointerEvents: 'none',
  transform: 'translateY(-4px) scale(0.79)',
  height: 14,
  '> p': {
    transition: 'transform 0.15s linear',
  },
  ...(enlarge && {
    transform: 'translateY(9px)',
  }),
}));

type TextFieldProps = React.HTMLProps<HTMLInputElement> & {
  icon?: React.ReactNode;
  mask?: string;
  errorMessage?: string;
};

const TextField: React.FC<TextFieldProps> = ({
  label,
  errorMessage,
  icon,
  autoFocus,
  mask,
  ...props
}) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation('yup');
  const ref = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleClick = () => {
    ref.current?.focus();
  };

  useEffect(() => {
    if (match && autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus, match]);

  return (
    <>
      <Container
        isFocused={isFocused}
        hasError={errorMessage}
        onClick={handleClick}
      >
        <Box display="flex" justifyContent="center" color={ROCK}>
          {icon}
        </Box>
        <Box display="flex" flexDirection="column" color={ROCK} flexGrow={1}>
          <LabelBox
            enlarge={!isFocused && !props.value}
            color={GRAPHITE}
            mb={0.5}
          >
            <Typography variant="body-l">{label} </Typography>
          </LabelBox>
          <Box>
            <Typography variant="body-l" fontWeight="medium">
              {!mask && (
                <Input
                  {...(props as React.InputHTMLAttributes<HTMLInputElement>)}
                  ref={ref}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
              )}
              {mask && (
                <InputMask
                  {...(props as React.InputHTMLAttributes<HTMLInputElement>)}
                  mask={mask}
                  maskPlaceholder=""
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                >
                  <input ref={ref} />
                </InputMask>
              )}
            </Typography>
          </Box>
        </Box>
      </Container>
      {errorMessage && (
        <Typography
          variant="body-s"
          color="error"
          style={{ color: DRAGON_FRUIT }}
        >
          {t(`${errorMessage}`)}
        </Typography>
      )}
    </>
  );
};

export default TextField;
