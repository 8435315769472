import { Typography } from '@bowtie-ins/bowkit';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentHead from 'src/components/DocumentHead';
import { Layout, VerificationForm } from 'src/features/auth/components';
import { LANGUAGE } from 'src/constants';

const Verify = () => {
  const { t, i18n } = useTranslation('auth');

  return (
    <Layout>
      <DocumentHead titleKey="meta_title.verification" />
      <Box textAlign="center" maxWidth={200} m="auto">
        <Typography variant="headline-s">{t('verification.title')}</Typography>
        <Box mt={1}>
          <Typography
            variant="body-l"
            fontWeight={i18n.language === LANGUAGE.ZH ? 'regular' : 'medium'}
          >
            {t('verification.desc')}
          </Typography>
        </Box>
      </Box>
      <VerificationForm />
    </Layout>
  );
};

export default Verify;
