import { Typography } from '@bowtie-ins/bowkit';
import styled from '@emotion/styled';
import { Box, BoxProps, Skeleton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import { colours } from 'src/constants';
import { ReportDayList } from 'src/features/report/components';
import { useListReport } from 'src/features/report/hooks';

type SkeletonBoxProps = BoxProps & {
  theme?: Theme;
};

const SkeletonBox = styled(Box)<SkeletonBoxProps>(({ theme }) => ({
  '& > span': {
    width: '100%',
    transform: 'none',
    marginBottom: theme.spacing(1.5),
    '&:nth-of-type(odd)': {
      height: 38,
      width: 54,
    },
    '&:nth-of-type(even)': {
      height: 98,
    },
    '&:nth-of-type(4)': {
      height: 150,
    },
    '&:last-child': {
      height: 150,
      width: '100%',
    },
  },
}));

const ReportList = () => {
  const [reports, { loading }] = useListReport();

  const renderReport = useMemo(() => {
    const filteredReports =
      reports?.filter(item => !!item.media || !!item.original_report) ?? [];
    const reportsGroupByDate = groupBy(filteredReports, item =>
      dayjs(item.issued_at).format('YYYY-MM-DD'),
    );
    const orderedDateReportsMapArray = orderBy(
      map(reportsGroupByDate, (v, k) => ({
        date: k,
        reports: v,
      })),
      ['date'],
      ['desc'],
    );
    const yearlyGroupedReports = groupBy(orderedDateReportsMapArray, item =>
      dayjs(item.date).year(),
    );
    return orderBy(
      map(yearlyGroupedReports, (v, k) => ({
        year: k,
        date: v,
      })),
      ['year'],
      ['desc'],
    );
  }, [reports]);

  if (loading) {
    return (
      <SkeletonBox>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SkeletonBox>
    );
  }
  return (
    <Box>
      {renderReport.map(item => (
        <Box key={item.year}>
          <Box color={colours.PRIMARY} mb={1}>
            <Typography variant="headline-s">{item.year}</Typography>
          </Box>
          {item.date.map(date => (
            <ReportDayList
              key={item.year + date.date}
              date={date.date}
              reports={date.reports}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default ReportList;
