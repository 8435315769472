import { useQuery } from '@apollo/client';
import { Patient, RETRIEVE_PATIENT_ME } from 'src/graphql';

const useMe = () => {
  const retrieve = useQuery<{ me: Patient }>(RETRIEVE_PATIENT_ME);

  return [retrieve.data?.me, retrieve] as const;
};

export { useMe };
