import Auth from '@aws-amplify/auth';
import Amplify, { Hub } from '@aws-amplify/core';
import Cookies from 'js-cookie';

type AmplifyOptions = {
  region?: string;
  userPoolId?: string;
  userPoolWebClientId?: string;
};

type CookiesOptions = {
  domain?: string;
  path?: string;
};

type InitAmplifyOptions = {
  cookies: CookiesOptions;
} & AmplifyOptions;

export const initAmplify = (options: InitAmplifyOptions) => {
  const {
    cookies: { domain, path },
    ...amplifyOptions
  } = options;
  Hub.listen('auth', async data => {
    const tokenKey = 'token';
    switch (data.payload.event) {
      case 'configured':
      case 'signIn':
      case 'signUp':
      case 'tokenRefresh':
        try {
          const currentSession = await Auth.currentSession();
          const idToken = currentSession.getIdToken();
          const expirationTimeStamp = idToken.getExpiration();
          const expirationDate = new Date(expirationTimeStamp * 1000);
          const token = idToken.getJwtToken();
          Cookies.set(tokenKey, token, {
            domain: domain || 'api.dev.bowtiejphealth.com',
            expires: expirationDate,
            path: path || '/report/',
            sameSite: 'strict',
            secure: window.location.protocol === 'https:',
          });
          break;
        } catch (error) {
          if (data.payload.event === 'configured') {
            /* Ignore errors when the session is not authenticated yet. */
            break;
          }
        }
        break;
      case 'signOut':
        Cookies.remove(tokenKey);
        break;
    }
  });
  Amplify.configure(amplifyOptions);
};
