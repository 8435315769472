import { Typography } from '@bowtie-ins/bowkit';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'src/components';
import DocumentHead from 'src/components/DocumentHead';
import { colours, LANGUAGE } from 'src/constants';
import { ReportList } from 'src/features/report/components';

const Home = () => {
  const { t, i18n } = useTranslation('report');

  return (
    <Layout maxWidth="md">
      <DocumentHead titleKey="meta_title.home" />
      <Box display="grid" rowGap={1} p={3} pb={6}>
        <Box>
          <Typography variant="headline-m">{t('report.title')}</Typography>
        </Box>
        <Box color={colours.PRIMARY}>
          <Typography
            variant="body-l"
            fontWeight={i18n.language === LANGUAGE.ZH ? 'regular' : 'medium'}
          >
            {t('report.report_tip')}
          </Typography>
        </Box>
        <ReportList />
      </Box>
    </Layout>
  );
};

export default Home;
