import { ApolloClient, InMemoryCache } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { RestLink } from 'apollo-link-rest';

const getToken = async (): Promise<string | undefined> => {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  } catch {
    return undefined;
  }
};

const getExtraHeaders = async (): Promise<Record<string, string>> => {
  const token = await getToken();
  return { ...(token && { authorization: `Bearer ${token}` }) };
};

const createRestLink = () => {
  return new RestLink({
    uri: process.env.REACT_APP_API_BASE_ENDPOINT,
    headers: {
      'content-type': 'application/json',
    },
    customFetch: async (requestUrl, requestParams) => {
      const originalHeaders: Record<string, string> = {};
      if (requestParams.headers !== undefined) {
        if (requestParams.headers.constructor === Headers) {
          (requestParams.headers as Headers).forEach((value, key) => {
            originalHeaders[key] = value;
          });
        } else {
          Object.entries(requestParams.headers).forEach(([key, value]) => {
            originalHeaders[key] = value;
          });
        }
      }
      const extraHeaders = await getExtraHeaders();
      const headers = { ...originalHeaders, ...extraHeaders };
      return fetch(requestUrl, { ...requestParams, headers });
    },
  });
};

export const initApolloClient = () =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: createRestLink(),
  });
