import { ServerError } from '@apollo/client';
import { INK_20, WHITE } from '@bowtie-ins/bowkit/palette';
import { Box, Breakpoint, Container, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import BowtieJpLogo from 'src/assets/img/logo.svg';
import { useAuth } from 'src/auth/context';
import { Avatar, LanguageButton, LayoutBox, LinkButton } from 'src/components';
import { HEADER_HEIGHT } from 'src/constants';
import { Path } from 'src/constants/path';
import { useMe } from 'src/features/account/hooks';
import { isDataPrivacyRead } from 'src/features/auth/utils';

const Layout = ({
  maxWidth,
  children,
}: {
  maxWidth?: Breakpoint;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  const { authenticated } = useAuth();
  const history = useHistory();
  const [, { error }] = useMe();

  if ((error?.networkError as ServerError)?.statusCode === 400) {
    history.push(Path.Verify);
  } else if (!isDataPrivacyRead()) {
    history.push(Path.DataPrivacy);
  }

  useEffect(() => {
    if (!authenticated) {
      history.push(Path.Login);
    }
  }, [authenticated, history]);

  return (
    <LayoutBox>
      <Box
        zIndex={theme.zIndex.appBar}
        position="sticky"
        top={0}
        display="flex"
        alignItems="center"
        p={2.5}
        bgcolor={WHITE}
        height={HEADER_HEIGHT}
        boxShadow={`0px -15px 20px 20px ${INK_20}`}
        justifyContent="space-between"
        borderRadius={5}
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <LinkButton to={Path.Home}>
          <img src={BowtieJpLogo} height="30.5" alt="logo" />
        </LinkButton>
        <Box mx={0.5}>
          <LanguageButton variant="body-m" />
          <LinkButton
            to={Path.Account}
            style={{ marginLeft: 2, borderRadius: '50%' }}
          >
            <Avatar />
          </LinkButton>
        </Box>
      </Box>
      <Container maxWidth={maxWidth || 'xs'} sx={{ padding: 0 }}>
        {children}
      </Container>
    </LayoutBox>
  );
};

export default Layout;
