import { useQuery } from '@apollo/client';
import { LIST_REPORTS, Report } from 'src/graphql';

const useListReport = () => {
  const retrieve = useQuery<{ reports: Report[] }>(LIST_REPORTS);

  return [retrieve.data?.reports, retrieve] as const;
};

export { useListReport };
