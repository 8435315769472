import { Typography } from '@bowtie-ins/bowkit';
import { ShieldSecurity } from '@bowtie-ins/bowkit/icons';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import { Path, LANGUAGE } from 'src/constants';
import { setDataPrivacyRead } from '../utils';

const DataPrivacyAgreement: React.FC = () => {
  const { t, i18n } = useTranslation('auth');
  console.log(i18n.language);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await Promise.all([
      setDataPrivacyRead(),
      new Promise(r => setTimeout(r, 1000)),
    ]);
    setLoading(false);
    history.push(Path.Home);
  };

  return (
    <Box mt={-4.75} px={3} pb={5}>
      <Box>
        <ShieldSecurity />
      </Box>
      <Box mb={1}>
        <Typography variant="headline-s">{t('data-privacy.title')}</Typography>
      </Box>
      <Box mb={4.5}>
        <Typography
          variant="body-l"
          fontWeight={i18n.language === LANGUAGE.ZH ? 'regular' : 'medium'}
        >
          {t('data-privacy.desc')}
          <br />
          <br />
          {t('data-privacy.desc2')}
          <br />
          <br />
          <Trans
            defaults={t('data-privacy.desc3')}
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a href="mailto:info@bowtiejphealth.com" />,
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              phone: <a href="tel:+85231692269" />,
            }}
          />
        </Typography>
      </Box>
      <Box>
        <Button
          size="s"
          type="submit"
          fullWidth
          loading={loading}
          disabled={loading}
          onClick={() => handleSubmit()}
        >
          {!loading && t('data-privacy.submit')}
        </Button>
      </Box>
    </Box>
  );
};

export default DataPrivacyAgreement;
