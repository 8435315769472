export const Path = {
  Root: `/`,
  Callback: {
    Login: `/l`,
  },
  Login: `/login`,
  Verify: `/verify`,
  Register: `/register`,
  DataPrivacy: `/data-privacy`,
  Report: {
    Root: `/report`,
  },
  Home: `/home`,
  Account: `/account`,
};
