import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const DocumentHead = ({ titleKey }: { titleKey?: string }) => {
  const { t } = useTranslation('meta_title');
  const base = t('meta_title.base');

  const createTitle = React.useCallback(
    (title?: string) => (title === base ? base : `${title}｜${base}`),
    [base],
  );

  return (
    <Helmet>
      <title>{!titleKey ? base : createTitle(t(titleKey, base))}</title>
    </Helmet>
  );
};
export default DocumentHead;
